import * as React from "react"

import ContactContent from "../components/ContactPage/ContactContent"
import Layout from "../components/layout"
import Seo from "../components/seo"

const ContactPage = () => (
  <Layout>
    <Seo title="Contact" />
    <ContactContent />
  </Layout>
)

export default ContactPage
