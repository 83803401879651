import React from 'react';
import ContactForm from './ContactForm/ContactForm';

import './ContactContent.css';

const ContactContent = () => {
    return (
        <div className="contact-layout"> 
            <div className="center-item-mobile">
                <h1 className="cta-text-mobile">Let's </h1>
                <div className="circle-highlight-sit-mobile">
                    <h1 className="cta-text-mobile">stay in touch!</h1>
                </div>
            </div>
            <ContactForm />
            <div className="contact-content-layout">
                <div style={{paddingBottom: "10px"}}/>
                <a className="social-style-mobile spacer" href="//www.linkedin.com/in/marko-stanoevich-1282a312b">linkedIn</a>
                <a className="social-style-mobile spacer" href="//www.twitter.com/MStanoevich">twitter</a>
                <p className="email-style-mobile spacer">contact@mstanoevi.ch</p>
            </div>
            <div className="contact-content-layout">
                <div className="center-item">
                    <h1 className="cta-text">Let's</h1>
                    <div className="circle-highlight-sit">
                        <h1 className="cta-text">stay in touch!</h1>
                    </div>
                </div>
                <>
                    <p className="email-style">contact@mstanoevi.ch</p>
                    <a className="social-style" href="//www.linkedin.com/in/marko-stanoevich-1282a312b">linkedIn</a>
                    <br />
                    <a className="social-style" href="//www.twitter.com/MStanoevich">twitter</a>
                </>
            </div>
        </div>
    );
};

export default ContactContent;