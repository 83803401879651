import React from 'react';

import { ThemeContext } from '../../ThemeContext';
import './ContactForm.css';

const ContactForm = () => {

  const { colorMode } = React.useContext(ThemeContext);

  return (
    <form method="post" netlify-honeypot="bot-field" data-netlify="true" name="contact" className="vertical-form">
      <input type="hidden" name="bot-field" />
      <input type="hidden" name="form-name" value="contact" />
      <input className={`input-area ${colorMode === "dark" ? "dark-mode-line dark-mode-text" : "light-mode-line"}`}
        name="name"
        type="text" 
        placeholder="Name" 
        id="name"/>
      <input  
          className={`input-area ${colorMode === "dark" ? "dark-mode-line dark-mode-text" : "light-mode-line"}`} 
          name="email"
          type="email" 
          placeholder="Email" 
          id="email" 
          required />
      <div className={`input-area ${colorMode === "dark" ? "dark-mode-line" : "light-mode-line"}`}>
        <textarea className={`message-input-area ${colorMode === "dark" ? "dark-mode-text" : ""}`} 
          name="message"
          placeholder="Message" 
          id="message" 
          rows="5" />
      </div>
      <button className={`submit-button ${colorMode === "dark" ? "dark-mode-button" : "light-mode-button"}`} type="submit">Submit -></button>
    </form>
  )
};

export default ContactForm;